import React from 'react';
import Image from '@components/image/image';
// styles
import styles from './data-security.module.scss';

interface IProps {
  title: string;
  description: any;
}

const DataSecurity: React.FC<IProps> = ({ title, description }) => {
  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <div className={styles.row}>
          <div className={styles.content}>
            <h2>{title}</h2>
            <hr />
            <div dangerouslySetInnerHTML={{ __html: description }} />
            <div className={styles.icons}>
              <Image src="/images/ssl.png" width={30} />
              <Image src="/images/gdpr.png" width={85} />
              <Image src="/images/hipaa.png" width={72} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DataSecurity;
