import { IDNAUpsellPrices } from '@components/account-result/utils';

export const ADD_TO_BASKET_OFFER_LABEL = 'Claim offer';
export const DISCOUNT_30_3_MONTHS_INFO =
  '<p><s>£39.95</s> <b>£27.96/mo</b> for 3 months.<br /><b>Discount applied at basket</b></p>';
export const DISCOUNT_50_INFO =
  '<p><s>£39.95</s> <b>£19.97</b> for your 1st month.<br /><b>Discount applied at basket</b></p>';
export const DISCOUNT_55_INFO =
  '<p><s>£39.95</s> <b>£17.98</b> for your 1st month.<br /><b>Discount applied at basket</b></p>';
export const DISCOUNT_70_INFO =
  '<p><s>£39.95</s> <b>£11.98</b> for your 1st month.<br /><b>Discount applied at basket</b></p>';
export const productAlternative: {
  [index: string]: { title: string; description: string; image: string };
} = {
  PP: {
    title: 'Want just the essentials?',
    description: 'We also have a single daily multivitamin personalised to you.',
    image: '99156415-6b01-482f-bb2b-b8902c60d288.jpeg',
  },
  EO: {
    title: 'How about an upgrade?',
    description: 'Get the full experience with the personalised pack tailored to your needs.',
    image: '6437b609-e65e-41fb-83d4-1f687f2b12d3.jpeg',
  },
};

export const DNA_UPSELL_PRICES: IDNAUpsellPrices = {
  USD: {
    dna: 165,
    total: 219.95,
    offer: 125.0,
    save: 94.95,
  },
  EUR: {
    dna: 165,
    total: 214.95,
    offer: 120.0,
    save: 94.95,
  },
  AUD: {
    dna: 179,
    total: 258.95,
    offer: 155.0,
    save: 103.95,
  },
  GBP: {
    dna: 139,
    total: 178.95,
    offer: 99,
    save: 79.95,
  },
};

export const THIRTY_PERCENT_OFF_3_MONTHS_PRICES: any = {
  GBP: {
    original: 39.95,
    thirtyPercentOff: 27.96,
    threeMonthSaving: 35.96,
    roundedSavings: 35,
  },
  USD: {
    original: 54.95,
    thirtyPercentOff: 38.46,
    threeMonthSaving: 49.46,
    roundedSavings: 49,
  },
  EUR: {
    original: 49.95,
    thirtyPercentOff: 34.96,
    threeMonthSaving: 44.96,
    roundedSavings: 44,
  },
  AUD: {
    original: 79.95,
    thirtyPercentOff: 55.96,
    threeMonthSaving: 44.96,
    roundedSavings: 44,
  },
};
