import React, { useState } from 'react';
import { Accordion as AccordionUI } from 'vitl-component-library';

interface IProps {
  items: Array<any>;
  isTransparent?: boolean;
  isHtmlContent?: boolean;
  openIfSingleItem?: boolean;
  withEffects?: boolean;
  className?: string;
  noIconBorder?: boolean;
}

const Accordion: React.FC<IProps> = ({
  withEffects = false,
  items,
  isTransparent = false,
  openIfSingleItem = false,
  isHtmlContent = false,
  className = '',
  noIconBorder = false,
}) => {
  return (
    <AccordionUI
      items={items}
      withEffects={withEffects}
      isTransparent={isTransparent}
      openIfSingleItem={openIfSingleItem}
      isHtmlContent={isHtmlContent}
      className={className}
      noIconBorder={noIconBorder}
    />
  );
};

export default Accordion;
