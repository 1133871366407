import { useQuery } from '@apollo/react-hooks';
import { DURATION_10_MINUTES } from '@src/constants/time';
import { USER_CLAIMED_FREE_TRIALS } from '@src/queries/user';
import { IUserClaimedFreeTrialsQueryResponse } from '@src/types/query-response';

export const useFreeTrial = () => {
  const { data } = useQuery<IUserClaimedFreeTrialsQueryResponse>(USER_CLAIMED_FREE_TRIALS, {
    pollInterval: DURATION_10_MINUTES
  });

  return {
    isFreeTrialUsed: data && data.user_claimedFreeTrials.length > 0 || false
  }
}