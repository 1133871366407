import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
// components
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import HowItWorks from '../../components/how-it-works';
import ExpertApproval from '../../components/expert-approval';
import Reviews from '@components/reviews/reviews';
import DataSecurity from '../../components/data-security';
import Accordion from '../../components/accordion';
import HeroNutritionTest from '../../components/hero-nutrition-test';
import Modal from '../../components/modal';
import ModalHeader from '../../components/modal/modal-header';
import ModalBody from '../../components/modal/modal-body';
import Image from '@components/image/image';
import { MODAL_TYPE } from '@components/modal/constants';
// services
import { trackProductViewed } from '../../services/tracking/tracking';
// styles
import styles from './dna.module.scss';
import AddToBasket from '../../components/add-to-basket';
// hooks
import useGetProductPlansData from '@src/features/shop/hooks/useGetProductPlansData';
// constants
import { OUT_OF_STOCK } from '@constants/messages';
import { SwiperComponent } from 'vitl-component-library';

const DnaNutritionTest = () => {
  const content = useStaticQuery(graphql`
    query DnaNutritionTest {
      directusDnaNutritionTest {
        product {
          identifier
        }
        page_title
        page_description
        hero_title
        hero_description
        hero_image {
          title
          filename_disk
        }
        hero_phone_image {
          title
          filename_disk
        }
        hero_phone_content_image {
          title
          filename_disk
        }
        hero_tablet_image {
          title
          filename_disk
        }
        discover_title
        discover_description
        discover_tiles {
          title
          description
          image_filename_disk
          bullets {
            bullet
          }
          reports
        }
        what_you_get_title
        what_you_get_description
        what_you_get_images {
          image_filename_disk
        }
        experts_title
        experts_description
        experts {
          name
          role
          qualification
          image {
            title
            filename_disk
          }
        }
        hiw_title
        hiw_footnote
        hiw_items {
          title
          description
          filename_disk
        }
        reviews_title
        reviews_items {
          reviewerName
          review
        }
        security_title
        security_description
        faqs_title
        faqs_description
        faqs {
          question
          answer
        }
        section_last_description
        section_last_button_label
        section_last_image {
          title
          filename_disk
        }
      }
    }
  `);

  const [showDiscoverModal, setShowDiscoverModal] = useState(-1);

  const { directusDnaNutritionTest } = content;

  const {
    product,
    page_title,
    page_description,
    hero_title,
    hero_description,
    hero_image,
    hero_phone_image,
    hero_phone_content_image,
    hero_tablet_image,
    discover_title,
    discover_description,
    discover_tiles,
    what_you_get_title,
    what_you_get_description,
    what_you_get_images,
    experts_title,
    experts_description,
    experts,
    hiw_title,
    hiw_footnote,
    hiw_items,
    reviews_title,
    reviews_items,
    security_title,
    security_description,
    faqs_title,
    faqs_description,
    faqs,
    section_last_description,
    section_last_image,
  } = directusDnaNutritionTest;
  const { dataPlans, isAvailable } = useGetProductPlansData(product.identifier);

  const location = useLocation();

  // tracking
  useEffect(() => {
    trackProductViewed({
      name: 'DNA Nutrition Test',
      url: location.href,
      product_id: product?.identifier,
      category: 'dna',
    });
  }, []);

  return (
    <Layout>
      <SEO title={page_title} description={page_description} />

      <HeroNutritionTest
        title={hero_title}
        description={hero_description}
        image={hero_image}
        phoneImage={hero_phone_image}
        phoneContentImage={hero_phone_content_image}
        phoneTabletImage={hero_tablet_image}
        plans={dataPlans?.product_plans ? dataPlans.product_plans : []}
      />

      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.content}>
              <h2>{discover_title}</h2>
              <hr />
              <p>{discover_description}</p>
            </div>
          </div>
          <div className={styles.tilesContainer}>
            {discover_tiles.map((tile: any, index: number) => (
              <div key={index} className={styles.tile}>
                <div onClick={() => setShowDiscoverModal(index)}>
                  <Image filename={tile.image_filename_disk} width={290} alt={tile.title} />
                  <span className={styles.tileFooter}>
                    <p>{tile.reports}</p>
                    <img src="/images/plus.png" alt="open popup" />
                  </span>
                </div>

                {showDiscoverModal === index && (
                  <Modal handleClose={() => setShowDiscoverModal(-1)} type={MODAL_TYPE.discover}>
                    <ModalHeader type={MODAL_TYPE.discover}>
                      <h2 className={styles.modalHeading}>{tile.title}</h2>
                    </ModalHeader>
                    <ModalBody>
                      <p className={styles.modalDescription}>{tile.description}</p>
                      <div className={styles.modalListWrapper}>
                        <ul>
                          {tile.bullets.map((bullet: any, index: number) => (
                            <li key={index}>{bullet.bullet}</li>
                          ))}
                        </ul>
                      </div>
                    </ModalBody>
                  </Modal>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className={styles.sectionPrimaryLighter}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.content}>
              <h2>{what_you_get_title}</h2>
              <hr />
              <p>{what_you_get_description}</p>
            </div>
          </div>

          <div className={styles.phoneContainer}>
            <SwiperComponent paginationType="bullets" spaceBetween={16} slidesPerView={1}>
              {what_you_get_images.map((image: any, index: number) => (
                <Image filename={image.image_filename_disk} width={470} />
              ))}
            </SwiperComponent>
          </div>
        </div>
      </section>

      <ExpertApproval
        title={experts_title}
        description={experts_description}
        experts={experts}
        backgroundColor="PrimaryLighter"
      />

      <HowItWorks title={hiw_title} items={hiw_items} footnote={hiw_footnote} />

      <Reviews title={reviews_title} items={reviews_items} showReview hideTrustPilot />

      <DataSecurity title={security_title} description={security_description} />

      <section className={styles.sectionPrimaryLighter}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.contentFaqs}>
              <h2>{faqs_title}</h2>
              <hr />
              <div
                className={styles.faqDescription}
                dangerouslySetInnerHTML={{ __html: faqs_description }}
              />
              <Accordion isHtmlContent={true} items={faqs} />
            </div>
          </div>
        </div>
      </section>

      <section className={styles.getToKnow}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.imageWrapper}>
              <div className={styles.imageContainer}>
                <Image filename={section_last_image.filename_disk} width={800} />
              </div>
            </div>
            <div className={styles.contentWrapper}>
              <div
                className={styles.subheading}
                dangerouslySetInnerHTML={{ __html: section_last_description }}
              />
              <AddToBasket
                showPlanLabel={false}
                plans={dataPlans.product_plans}
                isAvailable={isAvailable}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default DnaNutritionTest;
