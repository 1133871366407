import React from 'react';
import { HowItWorks as HowItWorksUI } from 'vitl-component-library';

interface IProps {
  title: string;
  description?: string;
  items: any;
  background?: string;
  footnote?: any;
  isWithCheckmarks?: boolean;
}

export interface IHiwItem {
  filename_disk: string;
  icon_filename_disk: string;
  src: string;
  title: string;
  description: string;
}

const HowItWorks: React.FC<IProps> = ({
  title,
  description,
  items,
  background = '',
  footnote = false,
  isWithCheckmarks = false,
}) => {
  return (
    <HowItWorksUI
      title={title}
      items={items}
      description={description}
      background={background}
      footnote={footnote}
      isWithCheckmarks={isWithCheckmarks}
    />
  );
};

export default HowItWorks;
